<template>
  <v-card
      class="messagerie-chat--list--simple"
      v-if="
          (arrayfilterActivate.length == 0 && !filterType) ||
            (arrayfilterActivate.length > 0 && filterType)
        "
  >
    <div v-if="loading" class="messagerie-chat--loader">
      <div class="messagerie-chat--loader-text">
        <p>
          {{ $t("messagerie.loading.loading_fiche") }}
          <v-progress-circular
              indeterminate
              color="primary"
          ></v-progress-circular>
        </p>
      </div>
      <v-skeleton-loader
          v-for="skeleton in [0, 1, 2, 3, 4]"
          :key="skeleton"
          ref="skeleton"
          loading
          type="list-item-avatar-three-line"
          class="mx-auto mb-3"
      ></v-skeleton-loader>
    </div>
    <vue-scroll
        ref="simpleScroll"
        class="vue-scroll-list-chat pr-2"
        :ops="optionsScrollChat"
        v-if="!loading && fiches.length > 0"
        @handle-scroll="$emit('scroll-end', $event)"
    >
      <message-card-mini-details
          v-for="fiche in fiches"
          :key="fiche.fiche.id"
          @click.native="$emit('select-fiche', fiche)"
          :fiche="fiche"
          :selected="selectedFiche == fiche"
          :user="user"
      ></message-card-mini-details>

      <div v-if="loadingScrollSimple" class="messagerie-chat--loader">
        <div class="messagerie-chat--loader-text">
          <p>
            {{ $t("messagerie.loading.loading_fiche") }}
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
          </p>
        </div>
      </div>
      <div
          v-if="endSimple && skipSimple != 0"
          class="primary--text text--lighten-2 text-center mx-auto mt-4"
      >
        {{ $t("messagerie.loading.end_fiche") }}
      </div>
    </vue-scroll>
    <v-alert color="orange" type="success" v-if="!loading && fiches.length == 0"
      >{{ $t("messagerie.loading.end_fiche") }}</v-alert
    >
  </v-card>
</template>

<script>
export default {
  name: "TicketListViewDenseDetailled",
  components: {
    MessageCardMiniDetails: () => import("@/Components/Views/Hotline/Ticket/TicketCardMiniDetails"),
  },
  props: {
    loading: Boolean,
    fiches: Array,
    filterType: Boolean,
    arrayfilterActivate: [Object, Array],
    optionsScrollChat: Object,
    selectedFiche: Object,
    endSimple: Boolean,
    skipSimple: Number,
    loadingScrollSimple: Boolean,
    user: Object,
    filterAdvancedActivate: Boolean
  },
  data() {
    return {
      panelsOpen: false
    }
  }
}
</script>

<style scoped>

</style>